import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import CKDocs from '@workday/canvas-kit-docs/dist/mdx/react/breadcrumbs/Breadcrumbs.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TabPanel = makeShortcode("TabPanel");
const PackageInfo = makeShortcode("PackageInfo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TabPanel data-id="Web" mdxType="TabPanel">
      <PackageInfo github="/modules/react/breadcrumbs" storybook="https://workday.github.io/canvas-kit/?path=/story/components-navigation-breadcrumbs--basic" figma="https://www.figma.com/design/6Y3uHyGKnS8m0DzWBLeL73/Canvas-Web-v11?node-id=4410-483&t=UhTeJ6F4tDSMVJmL-4" package="@workday/canvas-kit-react" mdxType="PackageInfo" />
      <h2 {...{
        "id": "anatomy"
      }}>{`Anatomy`}</h2>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/081bf04d0dff482c98d306f49dbbec1d/536c7/component-anatomy-breadcrumbs.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "39.729729729729726%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Image of a Breadcrumbs with annotation markers.",
              "title": "Image of a Breadcrumbs with annotation markers.",
              "src": "/static/081bf04d0dff482c98d306f49dbbec1d/50383/component-anatomy-breadcrumbs.png",
              "srcSet": ["/static/081bf04d0dff482c98d306f49dbbec1d/1efb2/component-anatomy-breadcrumbs.png 370w", "/static/081bf04d0dff482c98d306f49dbbec1d/50383/component-anatomy-breadcrumbs.png 740w", "/static/081bf04d0dff482c98d306f49dbbec1d/536c7/component-anatomy-breadcrumbs.png 1480w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <ol>
        <li parentName="ol"><strong parentName="li">{`Root Page`}</strong>{`: The root page grounds the trail as users navigate to subsequent pages.`}</li>
        <li parentName="ol"><strong parentName="li">{`Breadcrumb Pages`}</strong>{`: Each page has its own label. Pages are styled as
`}<a parentName="li" {...{
            "href": "/styles/tokens/type"
          }}>{`Hyperlink Text`}</a>{` and should be prepended by the word ‘Page’, ie: Page (#).`}</li>
        <li parentName="ol"><strong parentName="li">{`Current Page/Folder`}</strong>{`: All users should be aware of which page they are currently viewing. It
is not clickable.`}</li>
        <li parentName="ol"><strong parentName="li">{`Divider`}</strong>{`: The divider inherits the styling of our
`}<a parentName="li" {...{
            "href": "/components/buttons/button"
          }}>{`Icon Only Tertiary Button Variant`}</a>{`, but it is not clickable. Use the
Chevron System Icon.`}</li>
        <li parentName="ol"><strong parentName="li">{`Truncated Breadcrumbs`}</strong>{`: The Breadcrumb trail will truncate after the Breadcrumb hits a certain
width. Truncated pages hide within an
`}<a parentName="li" {...{
            "href": "/components/buttons/button"
          }}>{`Icon Only Tertiary Button Variant`}</a>{` styled with a Related Actions
System Icon. It should be clear to a screen reader user that there are additional pages that are
truncated.`}</li>
        <li parentName="ol"><strong parentName="li">{`Menu`}</strong>{`: This is the container that houses truncated pages. Use our Menu component. Screen
reader users should be able to navigate through and select a page within the menu.`}</li>
      </ol>
      <h2 {...{
        "id": "usage-guidance"
      }}>{`Usage Guidance`}</h2>
      <ul>
        <li parentName="ul">{`Breadcrumbs help to orient the user and keep track of their location as they navigate through a
site’s information hierarchy.`}</li>
        <li parentName="ul">{`The component should be used to supplement the primary or main navigation - not replace them.`}</li>
        <li parentName="ul">{`Breadcrumb trails always start with a root page that subsequent pages trace back to.`}</li>
        <li parentName="ul">{`Using Breadcrumbs should enable users to backtrack and jump to previous pages with ease.`}</li>
      </ul>
      <p><strong parentName="p">{`Placement`}</strong></p>
      <ul>
        <li parentName="ul">{`Breadcrumbs are represented as a trail of links anchored at the top left of a page, above the page
title but below the header.`}</li>
        <li parentName="ul">{`The position of Breadcrumbs should not move as the user navigates through different pages.`}</li>
      </ul>
      <p><strong parentName="p">{`Truncation`}</strong></p>
      <ul>
        <li parentName="ul">{`Although truncation behavior is built into the component, be thoughtful about the width of each
page title and how to collapse and truncate page items.`}</li>
        <li parentName="ul">{`Page titles and items will truncate after hitting a specified width (all items are set to truncate
at 350px, but can be customized).`}</li>
        <li parentName="ul">{`If truncation is required, truncated items will collapse into an Icon Only Tertiary Button
Variant, placed in-between the first item (root page) and last item (current page).`}</li>
        <li parentName="ul">{`Users can access truncated pages by clicking into the collapsible Icon Only Tertiary Button
Variant and selecting the desired page within the Menu.`}</li>
      </ul>
      <p><strong parentName="p">{`Responsive Behavior`}</strong></p>
      <ul>
        <li parentName="ul">{`On responsive or smaller screens, leverage the collapsible menu to shorten the Breadcrumb trail.`}</li>
        <li parentName="ul">{`Only truncate the root page if absolutely necessary.`}</li>
        <li parentName="ul">{`The current page is always visible and should never collapse into the overflow Menu.`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use"
      }}>{`When to Use`}</h3>
      <ul>
        <li parentName="ul">{`Use Breadcrumbs when users must navigate through a complex site or product.`}</li>
        <li parentName="ul">{`Use Breadcrumbs to track hierarchical navigation.`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use-something-else"
      }}>{`When to Use Something Else`}</h3>
      <ul>
        <li parentName="ul">{`Use other components for primary navigation. Breadcrumbs are used to supplement a global or
primary navigation.`}</li>
        <li parentName="ul">{`Consider removing Breadcrumbs altogether if you have a flat hierarchy that is only 1 or 2 levels
deep.`}</li>
      </ul>
      <CKDocs mdxType="CKDocs" />
      <h2 {...{
        "id": "accessibility-guidelines"
      }}>{`Accessibility Guidelines`}</h2>
      <h3 {...{
        "id": "keyboard-interaction"
      }}>{`Keyboard Interaction`}</h3>
      <p>{`Each link in the breadcrumbs must have a focus indicator that is highly visible against the
background and against the non-focused state. Refer to
`}<a parentName="p" {...{
          "href": "/guidelines/accessibility/accessible-color"
        }}>{`Accessible Colors`}</a>{` for more information.`}</p>
      <p>{`Breadcrumbs must support the following keyboard interactions:`}</p>
      <ul>
        <li parentName="ul"><inlineCode parentName="li">{`Tab`}</inlineCode>{`: Focuses each link in the breadcrumb navigation`}</li>
        <li parentName="ul"><inlineCode parentName="li">{`Enter`}</inlineCode>{`: Activates the currently focused link, or expands the truncated breadcrumb overflow menu`}</li>
        <li parentName="ul"><inlineCode parentName="li">{`Up Arrow`}</inlineCode>{` or `}<inlineCode parentName="li">{`Down Arrow`}</inlineCode>{`: Navigates up or down the truncated breadcrumb menu`}</li>
      </ul>
      <h3 {...{
        "id": "screen-reader-interaction"
      }}>{`Screen Reader Interaction`}</h3>
      <p>{`Breadcrumbs must communicate the following to users:`}</p>
      <ul>
        <li parentName="ul">{`Breadcrumbs are a navigation landmark, that can be distinguished from other site navigation
provided on a page`}</li>
        <li parentName="ul">{`How many breadcrumb links are shown`}</li>
        <li parentName="ul">{`Which breadcrumb link represents the current page`}</li>
        <li parentName="ul">{`There is a menu of “more links” when the breadcrumb component is truncated.`}</li>
      </ul>
      <h3 {...{
        "id": "design-annotations-needed"
      }}>{`Design Annotations Needed`}</h3>
      <p>{`No annotations required for Breadcrumbs.`}</p>
      <h3 {...{
        "id": "implementation-markup-needed"
      }}>{`Implementation Markup Needed`}</h3>
      <ul>
        <li parentName="ul">{`If the last element in the Breadcrumbs component is a link, an `}<inlineCode parentName="li">{`aria-current="page"`}</inlineCode>{` attribute
must be added to the link.`}</li>
        <li parentName="ul">{`[Included in component]`}{` Breadcrumb container must be a `}<inlineCode parentName="li">{`<nav>`}</inlineCode>{` element with an `}<inlineCode parentName="li">{`aria-label`}</inlineCode>{` string
`}<inlineCode parentName="li">{`“Breadcrumbs”`}</inlineCode>{`.`}</li>
        <li parentName="ul">{`[Included in component]`}{` Inside of the `}<inlineCode parentName="li">{`<nav>`}</inlineCode>{` element, breadcrumb links must be structured with
`}<inlineCode parentName="li">{`<ul>`}</inlineCode>{` and `}<inlineCode parentName="li">{`<li>`}</inlineCode>{` list markup.`}</li>
        <li parentName="ul">{`[Included in component]`}{` Chevron icons are decorative and should be hidden from screen readers.`}</li>
        <li parentName="ul">{`[Included in component]`}{` Truncated breadcrumbs use an icon button with an `}<inlineCode parentName="li">{`aria-label`}</inlineCode>{` string set
to `}<inlineCode parentName="li">{`“More links”`}</inlineCode>{` and an `}<inlineCode parentName="li">{`aria-haspopup=”true”`}</inlineCode>{` attribute to signal an attached menu will appear.`}</li>
      </ul>
      <h2 {...{
        "id": "content-guidelines"
      }}>{`Content Guidelines`}</h2>
      <p>{`Breadcrumb titles should inherit the same name as the page or product title the user has navigated
to. When writing content for Breadcrumbs, refer to our `}<a parentName="p" {...{
          "href": "/guidelines/content/overview"
        }}>{`Content Style Guide`}</a>{`.`}</p>
    </TabPanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      